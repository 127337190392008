export type VIDEO_FILE_EXTENSION = 'ogg' | 'mp4' | 'webm';
export type VIDEO_TYPE = {
  mimeType: string;
  fileExtension: VIDEO_FILE_EXTENSION;
};

const VIDEO_TYPE_OGG: VIDEO_TYPE = {
  mimeType: 'video/ogg',
  fileExtension: 'ogg',
};

const VIDEO_TYPE_MP4: VIDEO_TYPE = {
  mimeType: 'video/mp4',
  fileExtension: 'mp4',
};

const VIDEO_TYPE_WEBM: VIDEO_TYPE = {
  mimeType: 'video/webm',
  fileExtension: 'webm',
};

export const SUPPORTED_VIDEO_TYPES: VIDEO_TYPE[] = [
  VIDEO_TYPE_OGG,
  VIDEO_TYPE_MP4,
  VIDEO_TYPE_WEBM,
];
