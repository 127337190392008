import { createDefaultOptions } from '@component-library/fields';

export default class Field {
  id = null;
  template_section_id = null;
  field_type_id = null;
  system_reference = null;
  label = null;
  is_required = null;
  is_permanent = null;
  options = createDefaultOptions();
  order = null;
  c_template_field_id = null;
  c_input_value = null;
  created_at = null;
  updated_at = null;
  // Status checks
  isUpdating = false;
  isDeleting = false;

  static wrap(field) {
    if (field instanceof Field) {
      return field;
    }

    return new Field(field);
  }

  constructor({
    id = null,
    template_section_id = null,
    field_type_id = null,
    system_reference = null,
    label = null,
    is_required = false,
    is_permanent = false,
    options = createDefaultOptions(),
    order = 0,
    c_template_field_id = null,
    c_input_value = null,
    created_at = null,
    updated_at = null,
    isUpdating = false,
    isDeleting = false,
  }) {
    this.id = id;
    this.template_section_id = template_section_id;
    this.field_type_id = field_type_id;
    this.system_reference = system_reference;
    this.label = label;
    this.is_required = is_required;
    this.is_permanent = is_permanent;
    this.options = {
      ...createDefaultOptions(),
      ...options,
    };
    this.order = order;
    this.c_template_field_id = c_template_field_id;
    this.c_input_value = c_input_value;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.isUpdating = isUpdating;
    this.isDeleting = isDeleting;
  }

  async save() {
    if (this.id) {
      try {
        this.isUpdating = true;
        const { data } = await axios.put(`/api/template/field/${this.id}`, {
          id: this.id,
          template_section_id: this.template_section_id,
          field_type_id: this.field_type_id,
          system_reference: this.system_reference,
          label: this.label,
          is_required: this.is_required,
          is_permanent: this.is_permanent,
          options: {
            ...createDefaultOptions(),
            ...this.options,
          },
          order: this.order,
          c_template_field_id: this.c_template_field_id,
          c_input_value: this.c_input_value,
        });
        this.created_at = data.data.created_at;
        this.updated_at = data.data.updated_at;
      } finally {
        this.isUpdating = false;
      }

      return;
    }

    const { data } = await axios.post(`/api/template/field`, {
      template_section_id: this.template_section_id,
      field_type_id: this.field_type_id,
      system_reference: this.system_reference,
      label: this.label,
      is_required: this.is_required,
      is_permanent: this.is_permanent,
      options: {
        ...createDefaultOptions(),
        ...this.options,
      },
      order: this.order,
      c_template_field_id: this.c_template_field_id,
      c_input_value: this.c_input_value,
    });
    this.id = data.data.id;
    this.created_at = data.data.created_at;
    this.updated_at = data.data.updated_at;
  }

  async delete() {
    try {
      this.isDeleting = true;
      const { data } = await axios.delete(`/api/template/field/${this.id}`);
      return data;
    } catch ({ response: e }) {
      throw e;
    } finally {
      this.isDeleting = false;
    }
  }
}
