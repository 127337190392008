import * as bm from '../business-model';

export function getSupportedVideoFileExtensions(): bm.media.VIDEO_FILE_EXTENSION[] {
  return bm.media.SUPPORTED_VIDEO_TYPES.map((item) => item.fileExtension);
}

export function getVideoMimeTypeByFileExtension(
  fileExtension: bm.media.VIDEO_FILE_EXTENSION
): string | null {
  return (
    bm.media.SUPPORTED_VIDEO_TYPES.find(
      (item) => item.fileExtension === fileExtension
    )?.mimeType ?? null
  );
}
