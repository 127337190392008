export function normalizeLongitude(value) {
  return parseFloat(value.toFixed(7));
}

export function normalizeLatitude(value) {
  return parseFloat(value.toFixed(7));
}

export function normalizeElevation(value) {
  return parseFloat(value.toFixed(3));
}

export function normalizeCorrectedElevation(value) {
  return parseFloat(value.toFixed(3));
}
